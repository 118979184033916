// 自定义指令
import Clipboard from "clipboard";
import { Message } from "element-ui";
import dictService from "@/utils/dictService";

let dictDataPromiseMap = new Map();

export default (Vue) => {
	// 按钮权限（可见性）
	Vue.directive('btnAuth', {
		inserted: (el, binding, vnode) => {
			let { auth_button } = vnode.context.$route.meta;
			if (auth_button && auth_button.length > 0) {
				// if (!el.children.length) {
				// 	el.remove()
				// }
				if (Array.isArray(binding.value)) {
					let hasNode = auth_button.some(v => {
						return binding.value.includes(v.button_type);
					});
					let array = [];
					auth_button.forEach(v => {
						let item = binding.value.find(_v => { return v.button_type == _v });
						if (item) array.push(v);
					})
					if (!hasNode || !array.filter(v => v.display).length) {
						if (el && el.parentNode) {
							el.parentNode.removeChild(el)
						}
					}
				} else {
					let item = auth_button.find(v => { return (v.button_type == binding.value && v.display) });
					if (!item) {
						if (el && el.parentNode) {
							el.parentNode.removeChild(el)
						}
					}
				}
			} else {
				if (el && el.parentNode) {
					el.parentNode.removeChild(el)
				}
			}
		}
	});

	Vue.directive('debounce', {
		inserted(el, binding) {
			el.addEventListener('click', e => {
				el.classList.add('is-disabled')
				el.disabled = true
				setTimeout(() => {
					el.disabled = false
					el.classList.remove('is-disabled')
				}, 2000)
			})
		}
	});
	Vue.directive('dictConvert', {
		bind(el, binding, vnode) {
			dictConvert(el, binding, vnode)
		},
		componentUpdated(el, binding, vnode) {
			dictConvert(el, binding, vnode)
		},
	})

	Vue.directive('copy', {
		bind(el, binding) {
			copy(el, binding)
		},
		componentUpdated(el, binding) {
			copy(el, binding)
		}
	})
}

function copy(el, binding) {
	let randomNum = (Math.random() * 10000).toFixed(0);
	el.setAttribute('class', `copy-${randomNum}`);
	el.removeEventListener('click', () => {});
	el.addEventListener('click', () => {
		let text = binding.value.text;
		let fn = binding.value.fn;
		let clipboard = new Clipboard(`.copy-${randomNum}`, {
			text: () => {
				if (isNumber(text)) {
					return String(text);
				}
				return text
			},
		});
		clipboard.on('success', (e) => {
			if (fn) {
				fn({ status: 'success', data: e });
			} else {
				Message.success("复制成功");
			}
			clipboard.destroy();
		});
		clipboard.on('error', (e) => {
			if (fn) {
				fn({ status: 'error', data: e });
			} else {
				Message.error('复制失败');
			}
			clipboard.destroy();
		});
	})
}

function formatDictValue(el, dictData, vnode) {
	const text = vnode.children[0].text

	// if (!dictData || !el.innerHTML) return;
	if (!dictData || !text) return;
	let htmlText = null;

	try {
		htmlText = eval(vnode.children[0].text);
	} catch (e) {
		htmlText = vnode.children[0].text
	}

	try {
		if (Array.isArray(htmlText)) {
			let newText = [];
			htmlText.forEach(v => {
				let item = dictData.find(_v => _v.value == v);
				if (item) newText[newText.length] = item.label;
			})
			el.innerHTML = newText.join('、')
		} else {
			let item = dictData.find(v => v.value == htmlText);
			if (item) el.innerHTML = item.label;
		}
	} catch (error) {
		console.error('dict convert error ===>', error)
	}
}

function dictConvert(el, binding, vnode) {

	if (binding.value.dictData) {
		formatDictValue(el, binding.value.dictData, vnode)
	} else {
		if (!binding.value.dictType || !binding.value.dictName) return;
		if (!dictDataPromiseMap.has(binding.value.dictType)) {
			let dictDataPromise = new Promise(async resolve => {
				const dictData = await dictService.getDictByType({ type: binding.value.dictType });
				resolve(dictData)
			})
			dictDataPromiseMap.set(binding.value.dictType, dictDataPromise);
		};
		dictDataPromiseMap.get(binding.value.dictType).then(dictData => {
			formatDictValue(el, dictData[binding.value.dictName], vnode)
		})
	}
}


function isNumber(value) {
	return typeof value === 'number' && isFinite(value);
}
